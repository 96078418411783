import request from "@/utils/request";

/**
 * 添加宥马用户
 * @param data 宥马用户信息
 */
export async function addYmUser(data) {
  const res = await request.post("/ym", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 查询宥马用户列表
 * @param params 查询条件
 */
export async function listYmUser(params) {
  const res = await request.get("/ym", {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 删除宥马用户
 * @param id 用户id
 */
export async function removeYmUser(id) {
  const res = await request.delete("/ym/" + id);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 查询单个用户
 * @param id 用户id
 */
export async function getYmUser(uin) {
  const res = await request.get("/ym/" + uin);
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 修改信息表
 * @param data 信息表信息
 */
export async function updateYm(data) {
  const res = await request.put("/ym", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}
/**
 * 查询单个用户
 * @param id 用户id
 */
export async function getLogs(params) {
  const res = await request.get("/ym/logs", {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 执行跑步任务
 * @param id 用户id
 * @param runType 任务类型
 */
export async function runTask(data) {
  const res = await request.post("/ym/run", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}
