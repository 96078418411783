<template>
  <div class="content" id="pjax-container">
    <div class="row">
      <div class="col-12">
        <div class="block block-rounded">
          <div class="block-header block-header-default">
            <h3 class="block-title">全部账号列表 - 宥马运动</h3>
            <div class="fw-semibold text-success">
              剩余未跑：{{ totalCount }}次
            </div>
          </div>
        </div>
      </div>
      <div class="input-group input-group-lg search">
        <input
          type="text"
          class="form-control"
          placeholder="搜索 宥马用户"
          v-model="search"
          @keyup.enter="searchUser"
        />
        <button type="submit" class="btn btn-primary" @click="searchUser">
          <i class="fa fa-search"></i>
        </button>
      </div>

      <div v-if="filteredList.length === 0" class="col-12">
        <div class="block block-rounded">
          <div class="block-content text-center">
            <i class="si si-drawer fa-2x"></i>
            <p class="text-muted fs-sm">暂无数据</p>
          </div>
        </div>
      </div>

      <div
        v-for="account in filteredList"
        :key="account.id"
        class="col-md-6 col-xl-3"
      >
        <div class="block block-rounded text-center">
          <div class="block-content block-content-full bg-pulse-lighter">
            <img
              class="img-avatar img-avatar-thumb"
              :src="account.avatar"
              alt=""
            />
          </div>
          <div class="block-content">
            <div class="fw-semibold mb-1">
              {{ account.nickName }}({{ account.uin }})
            </div>
            <div class="fw-semibold text-warning">
              剩{{ account.jobCount }}次
            </div>

            <div v-if="account.state === 1" class="fs-sm text-success mb-1">
              正常运行中
            </div>

            <div v-else class="fs-sm text-danger mb-1">状态已失效</div>
            <div class="fs-sm text-muted">
              <i class="far fa-clock opacity-75"></i> {{ account.executeTime }}
            </div>
          </div>
          <div class="block-content block-content-full">
            <div class="row g-sm">
              <div class="col-8">
                <router-link
                  class="btn btn-sm btn-alt-primary w-100"
                  :to="{ path: '/youma/Info', query: { uin: account.uin } }"
                  >管理账号</router-link
                >
              </div>
              <div class="col-4">
                <button
                  class="btn btn-sm btn-alt-danger w-100"
                  @click="del(account.id, account.uin)"
                >
                  <i class="si si-close"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { listYmUser, removeYmUser } from "@/api/youma";
import { ref, computed } from "vue";
import { ElMessageBox } from "element-plus";

const list = ref([]);
const search = ref("");
const totalCount = ref(0);

listYmUser().then((res) => {
  list.value = res;
  totalCount.value = res
    .map((item) => item.jobCount)
    .reduce((a, b) => a + b, 0);
});

const filteredList = computed(() => {
  if (search.value) {
    return list.value.filter(
      (account) =>
        account.nickName.includes(search.value) ||
        account.uin.includes(search.value)
    );
  }
  return list.value;
});

const searchUser = () => {
  // This function is triggered on the enter key press or button click
  // It doesn't need to do anything because the computed property filteredList already handles the filtering
};

const del = (id, uin) => {
  ElMessageBox.confirm(`你确定要删除${uin}吗？`, "注意", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    removeYmUser(id).then(() => {
      list.value = list.value.filter((item) => item.id !== id);
      totalCount.value = list.value
        .map((item) => item.jobCount)
        .reduce((a, b) => a + b, 0);
    });
  });
};
</script>

<style scoped>
.search {
  margin-bottom: 20px;
}
</style>
